import { auth } from "@/services/auth";

export const addUpdateData = <T>(data: T) => {
  return {
    ...data,
    updatedAt: new Date(),
    updatedBy: auth.currentUser?.uid ?? "UNKNOWN",
  };
};
export const addCreationData = <T>(data: T) => {
  return {
    ...addUpdateData(data),
    _deleted: false,
    createdAt: new Date(),
    createdBy: auth.currentUser?.uid ?? "UNKNOWN",
  };
};
