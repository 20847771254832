export const Spinner = () => {
  return (
    <div
      className="inline-block animate-spin-slow rounded-full align-[-0.125em] motion-reduce:animate-[spin_2.5s_linear_infinite] border-dkGray border-dashed h-12 w-12 border-8"
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        {" "}
      </span>
    </div>
  );
};
