import { PropsWithChildren } from "react";
import { FlagsProvider as BaseFlagsProvider } from "flagged";
import { useStore } from "@/stores/useStore";
export const FlagsProvider = ({ children }: PropsWithChildren) => {
  const featureFlagsStore = useStore((s) => s.featureFlags);
  console.log(featureFlagsStore);
  return (
    <BaseFlagsProvider features={featureFlagsStore}>
      {children}
    </BaseFlagsProvider>
  );
};
