import { Button } from "@/components/Base/Button";
import { CardForm } from "@/components/Dashboard/components/Card/CardForm";
import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { useIsSuperAdmin } from "@/hooks/useIsSuperAdmin";
import { useSuperAdminCheck } from "@/hooks/useSuperAdminCheck";
import { shop } from "@/models/shop";

const DebugCardCreatePage = () => {
  const superAdminCheck = useSuperAdminCheck();
  useIsAuthenticated();

  if (superAdminCheck) return superAdminCheck;

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold ">Card Form</h1>
      <div className="flex flex-wrap gap-2">
        <CardForm />
      </div>
    </div>
  );
};

export default DebugCardCreatePage;
