import { firestore } from "@/services/firestore";
import { storage } from "@/services/storage";
import { CardContents, Container, Layout, Layouts, Shop } from "@/types/Shop";
import { Unsubscribe } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { StateCreator } from "zustand";
import { config } from "@/config";
import { updateLastSyncedTimestamp } from "../data/LastSynced/updateLastSyncedTimestamp";
import { Timer } from "@/types/Timer";
import { employees } from "@/models/employees";
import { card } from "@/models/cards";
import { Employee } from "@/types/Employee";
import { KEYS, localStorageManager } from "@/services/localStorage";
import { FeatureFlagEnabled } from "@/types/FeatureFlag";
import { featureFlags } from "@/models/featureFlags";
import { sentry } from "@/services/sentry";
import { UserSlice } from "./user.slice";

export interface ShopSlice {
  shop: Shop | undefined;
  shopId: string | undefined;
  logoUrl: string | undefined;
  logoExists: boolean;
  subscribeShop: (id: string) => void;
  shopSubscription: Unsubscribe | undefined;
  unsubscribeShop: () => void;
  getLogoUrl: () => Promise<void>;
  dashboardServiceWriterId: string | undefined;
  setDashboardServiceWriterId: (id: string) => void;
  dashboardLayoutId: string | undefined;
  setDashboardLayoutId: (id: string) => void;
  refreshTimeInSeconds: number;
  isSyncing: boolean;
  lastSyncedTime: number;
  syncWithTekmetric: (force?: boolean) => void;
  syncButtonText: string | undefined;
  syncError: boolean;
  timers: { [x: string]: Timer } | undefined;
  subscribeTimers: () => void;
  timersSubscription: Unsubscribe | undefined;
  employees: Employee[] | undefined;
  subscribeEmployees: () => void;
  employeesSubscription: Unsubscribe | undefined;
  unsubscribeEmployees: () => void;
  setCurrentShopId: (id: string | null) => void;
  featureFlags: FeatureFlagEnabled | undefined;
  setFeatureFlags: () => void;

  //

  containers: Shop["containers"];
  layouts: Shop["layouts"];
  defaultTimer: Shop["defaultTimer"];
  cardContents: Shop["cardContents"];
  cartLabelWrap: Shop["cardLabelWrap"];
  statuses: Shop["statuses"];
  lastSyncedTimestamp: Shop["lastSyncedTimestamp"];
  refreshRate: Shop["refreshTimeInSeconds"];
}

const initialShopSlice: ShopSlice = {
  shop: undefined,
  shopId: undefined,
  logoUrl: undefined,
  logoExists: false,
  subscribeShop: () => {},
  shopSubscription: undefined,
  unsubscribeShop: () => {},
  getLogoUrl: async () => {},
  dashboardServiceWriterId:
    window.localStorage.getItem("shdbServiceWriterId") || undefined,
  setDashboardServiceWriterId: () => {},
  dashboardLayoutId: window.localStorage.getItem("shdbLayoutId") || "0",
  setDashboardLayoutId: () => {},
  refreshTimeInSeconds: 300,
  isSyncing: false,
  lastSyncedTime: 0,
  syncWithTekmetric: () => {},
  syncButtonText: "Sync with Tekmetric",
  syncError: false,
  timers: undefined,
  subscribeTimers: () => {},
  timersSubscription: undefined,
  employees: [],
  subscribeEmployees: () => {},
  employeesSubscription: undefined,
  unsubscribeEmployees: () => {},
  setCurrentShopId: () => {},
  featureFlags: undefined,
  setFeatureFlags: () => {},
  containers: [],
  layouts: [],
  defaultTimer: "",
  cardContents: {},
  cartLabelWrap: "",
  statuses: [],
  lastSyncedTimestamp: 0,
  refreshRate: 300,
};

export const createShopSlice: StateCreator<
  ShopSlice & UserSlice,
  [],
  [],
  ShopSlice
> = (set, get) => ({
  ...initialShopSlice,
  setCurrentShopId: (id: string | null) => {
    localStorageManager.setItem(KEYS.SHOP_ID, id);
    set({ shopId: id === null ? undefined : id });

    if (id === "unverified" || id === null) {
      get().unsubscribeShop();
      return;
    } else {
      const user = get().user;
      sentry.setUser({ id: user?.id, email: user?.email, shopId: id });
    }
    get().subscribeShop(id);
  },
  setDashboardServiceWriterId: (id) => {
    set({ dashboardServiceWriterId: id });
    window.localStorage.setItem("shdbServiceWriterId", id);
  },
  setDashboardLayoutId: (id) => {
    set({ dashboardLayoutId: id });
    window.localStorage.setItem("shdbLayoutId", id);
  },
  // DO NOT CALL YET
  subscribeShop: (id: string) => {
    const subscription = firestore.subscribeDocument<Shop>(
      `shops/${id}`,
      (doc) => {
        set({
          shop: doc,
          lastSyncedTime: doc?.lastSyncedTimestamp ?? 0,
          containers: doc?.containers ?? [],
          layouts: doc?.layouts ?? [],
          defaultTimer: doc?.defaultTimer ?? "",
          cardContents: doc?.cardContents ?? {},
          cartLabelWrap: doc?.cardLabelWrap ?? "",
          statuses: doc?.statuses ?? [],
          lastSyncedTimestamp: doc?.lastSyncedTimestamp ?? 0,
          refreshRate: doc?.refreshTimeInSeconds ?? 300,
          logoUrl: doc?.logoUrl ?? undefined,
        });
      }
    );

    set({ shopSubscription: subscription, shopId: id });
    get().getLogoUrl();
    get().subscribeEmployees();
    get().subscribeTimers();
    get().setFeatureFlags();
  },
  unsubscribeShop: () => {
    const unsubscribe = get().shopSubscription;
    if (unsubscribe) unsubscribe();

    const unsubscribeEmployees = get().employeesSubscription;
    if (unsubscribeEmployees) unsubscribeEmployees();

    const unsubscribeTimers = get().timersSubscription;
    if (unsubscribeTimers) unsubscribeTimers();

    set({ shopSubscription: undefined, shop: undefined, shopId: undefined });
  },
  getLogoUrl: async () => {
    const shop = get().shop;
    if (!shop) return;
    if (shop.logoUrl) {
      set({ logoUrl: shop.logoUrl });
      return;
    }

    const logoRef = ref(storage, `${shop?.shopId}/logo`);
    const path = logoRef.fullPath;

    try {
      const checkUrl = await getDownloadURL(ref(storage, path)).then((url) => {
        return url;
      });
      // console.log("Getting Logo");
      set({ logoUrl: checkUrl });

      var img = new Image();
      img.onload = function () {
        set({ logoExists: true });
      };
      img.onerror = function () {
        set({ logoExists: false });
        img.src = checkUrl;
      };
    } catch (error) {
      console.error(error);
      set({ logoExists: false, logoUrl: undefined });
    }
  },
  syncWithTekmetric: (force: boolean = false) => {
    const ff = get().featureFlags;
    if (ff && ff["isManualCardOnlyEnabled"]) {
      console.log("manual card creation only. Skipping sync");
      return;
    }
    const lastSyncedTime = get().lastSyncedTime;
    const isSyncing = get().isSyncing;
    const refreshTimeInSeconds = get().refreshRate;

    const timeSinceSync =
      (new Date().setMilliseconds(0) - lastSyncedTime) / 1000;
    // console.log(
    //   "@syncWithTekmetric secondsSinceUpdate:",
    //   timeSinceSync,
    //   new Date(lastSyncedTime)
    // );

    if ((timeSinceSync > refreshTimeInSeconds && !isSyncing) || force) {
      set({ syncButtonText: "Syncing data..." });

      const timestamp = Date.now();

      if (!config.apiEndpoints.tekmetric) return;
      const shopId = get().shopId;
      if (!shopId) return;
      // build auth here
      set({ isSyncing: true });
      // console.log("calling buildTodaysTags. payload: ", payload);
      card
        .callBuildCards(shopId)
        .then(async () => {
          // console.log("in buildTodaysTags. resp: ", resp);
          set({ lastSyncedTime: timestamp, syncError: false });
          await updateLastSyncedTimestamp({
            shopId,
            lastSyncedTimestamp: timestamp,
          });
        })
        .catch((error: any) => {
          console.error(error);
          set({ syncError: true });
        })
        .finally(() => {
          set({ syncButtonText: "Sync with Tekmetric", isSyncing: false });
        });
    } else {
      console.log("syncing not needed");
    }
  },
  subscribeTimers: () => {
    const shopId = get().shopId;
    if (!shopId) return;
    const subscription = firestore.subscribeCollection<Timer>(
      `shops/${shopId}/timers`,
      (docs) => {
        const reducedTimers = docs.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {}
        );
        set({ timers: reducedTimers });
      }
    );
    set({ timersSubscription: subscription });
  },
  unsubscribeTimers: () => {
    const unsubscribe = get().timersSubscription;
    if (unsubscribe) unsubscribe();
    set({ timersSubscription: undefined, timers: undefined });
  },
  subscribeEmployees: () => {
    const shop = get().shop;
    if (shop) {
      set({
        employeesSubscription: employees.subscribeAll(
          shop.shopId,
          (employees) => set({ employees })
        ),
      });
    }
  },
  unsubscribeEmployees: () => {
    const unsubscribe = get().employeesSubscription;
    if (unsubscribe) unsubscribe();
    set({ employeesSubscription: undefined, employees: [] });
  },
  setFeatureFlags: async () => {
    const ff = await featureFlags.list();
    const enabledFf: FeatureFlagEnabled = {};
    const shopId = get().shopId;
    if (!shopId) return;
    if (ff) {
      Object.keys(ff).forEach((key) => {
        if (ff[key].allUsers) {
          enabledFf[key] = ff[key].allUsers;
        } else {
          enabledFf[key] = ff[key].testUsers.includes(shopId);
        }
      });
    }
    set({ featureFlags: enabledFf });
  },
});
