import { useIsSuperAdmin } from "./useIsSuperAdmin";

export const useSuperAdminCheck = () => {
  const [initialized, isSuperAdmin] = useIsSuperAdmin();

  if (!initialized) return <div>Loading...</div>;
  if (!isSuperAdmin) return <div>403</div>;

  return null; // If all is good
};
