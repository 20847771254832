import { entity } from "simpler-state";

const initialState = {};

export const subscribersEntity = entity(initialState);

/**
 * Adds a new subscriber function to the list of subscribers for the specified key.
 *
 * @function
 * @param {Function} subscriber - The function to be added to the subscribers list.
 * @param {string} key - The key to associate the subscriber with.
 * @returns {void}
 */
export const pushSubscriber = (subscriber, key) => {
  let subs = subscribersEntity.get()

  // Check if the key exists and if the corresponding value is a function
  console.log("subs", subs);
  console.log("key", key);
  console.log("subs[key]", subs[key])
  console.log("typeof subs[key]", typeof subs[key])
  if (subs[key] && typeof subs[key] === 'function') {
    // console.log("unsubbing from ", key);
    try {
      subs[key]();
    } catch (e) {
      console.error(e);
    }
  } else {
    console.log(`Expected a function at subs[${key}], but found:`, subs[key]);
  }

  // Assign the new subscriber function to the key
  subs[key] = subscriber;

  // console.log("Subscribers: ", Object.keys(subs).length);
  // console.log(Object.keys(subs));

  subscribersEntity.set(subs);
};


/**
 * Removes all subscribers from the subscribers list and returns the keys of the removed subscribers.
 *
 * @function
 * @returns {Array<string>} An array of keys representing the removed subscribers.
 */
export const resetSubscribers = () => {
  const subscribers = subscribersEntity.get();
  Object.keys(subscribers).forEach((key) => {
    // console.log("unsubbing from ", key);
    try {
      subscribers[key]();
      delete subscribers[key];
    } catch (e) {
      console.log(`Unable to unsubscribe from ${key}`, e);
    }
  });

  subscribersEntity.set(subscribers);
  return Object.keys(subscribers);
};

/**
 * Removes the subscriber associated with the specified key from the subscribers list.
 *
 * @function
 * @param {string} key - The key associated with the subscriber to remove.
 * @returns {void}
 */
export const removeSubscriber = (key) => {
  let subs = subscribersEntity.get();
  const subscriber = subs[key];
  if (subscriber) {
    subscriber();
    delete subs[key];
  }
  subscribersEntity.set(subs);
};
