import { clsx, type ClassValue } from "clsx";
import { Timestamp } from "firebase/firestore";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function arrayUnion<T>(value: T, array: T[]) {
  return [...array.filter((item) => item !== value), value];
}

export function arrayRemove<T>(value: T, array: T[]) {
  return [...array.filter((item) => item !== value)];
}

const getBrightness = (color: string) => {
  const hexColor = color.replace(/^#/, "");
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);
  return (r * 299 + g * 587 + b * 114) / 1000;
};

// Determine the text color based on the background color's brightness
export const getTextColor = (color: string | "transparent") => {
  const brightness = getBrightness(color);
  if (isNaN(brightness)) return undefined;
  return getBrightness(color) > 128 ? "black" : "white";
};

export function formatArrayAsString(arr: string[]) {
  const arrToFormat = [...arr];
  if (arrToFormat.length === 1) {
    return arrToFormat[0];
  } else if (arrToFormat.length === 2) {
    return arrToFormat.join(" and ");
  } else {
    const last = arrToFormat.pop();
    return `${arrToFormat.join(", ")}, and ${last}`;
  }
}

export function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function firestoreToDateTime(firestoreTime: Timestamp | Date) {
  if (firestoreTime instanceof Date) return firestoreTime;
  return firestoreTime?.toDate();
}
