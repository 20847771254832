import packageJson from "../package.json";

const config = {
  version: packageJson.version,
  commitHash: __COMMIT_HASH__,
  mode: import.meta.env.MODE || "development",
  env: import.meta.env.VITE_ENVIRONMENT,
  emulator: import.meta.env.MODE === "emulator",
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
  },
  urlLinks: {
    tekmetric: import.meta.env.VITE_TEKMETRIC_URL,
  },
  apiEndpoints: {
    tekmetric: import.meta.env.VITE_TEKMETRIC_API_URL,
  },
  availableFeatureFlags: {
    isCardColorEnabled:
      import.meta.env.VITE_FF_ISCARDCOLORENABLED_ACTIVE === "true",
    isCardLaborEnabled:
      import.meta.env.VITE_FF_ISCARDLABORENABLED_ACTIVE === "true",
    isCardPrimaryLinkEnabled:
      import.meta.env.VITE_FF_ISCARDPRIMARYLINKENABLED_ACTIVE === "true",
    isPromiseTimeEnabled:
      import.meta.env.VITE_FF_ISPROMISETIMEENABLED_ACTIVE === "true",
    isServiceAdvisorFilterEnabled:
      import.meta.env.VITE_FF_ISSERVICEADVISORFILTERENABLED_ACTIVE === "true",
    isSyncV2Enabled: import.meta.env.VITE_FF_ISSYNCV2ENABLED_ACTIVE === "true",
    isTechnicianLinkEnabled:
      import.meta.env.VITE_FF_ISTECHNICIANLINKENABLED_ACTIVE === "true",
    isROLabelLinkEnabled:
      import.meta.env.VITE_FF_ISROLLABELLINKENABLED_ACTIVE === "true",
    isCardColorSyncEnabled:
      import.meta.env.VITE_FF_ISCARDCOLORSYNCENABLED_ACTIVE === "true",
    isManualCardOnlyEnabled:
      import.meta.env.VITE_FF_ISMANUALCARDONLYENABLED_ACTIVE === "true",
    isManualCardsEnabled:
      import.meta.env.VITE_FF_ISMANUALCARDSENABLED_ACTIVE === "true",
    isCardNotesEnabled: import.meta.env.VITE_FF_CARDNOTES_ACTIVE === "true",
  },
};

console.log(config);
export { config };
