import { firestore } from "@/services/firestore";
import { httpsCallables } from "./httpsCallables";
import { Card, CreateCardDto, PatchCardDto } from "@/types/Card";
import shortUUID from "short-uuid";
import { shop } from "./shop";
import { addCreationData } from "@/lib/database";

export const card = {
  get: async (shopId: string, cardId: string) => {
    return firestore.getDocument<Card>(`shops/${shopId}/cards/${cardId}`);
  },
  callBuildCards: async (shopId: string) => {
    try {
      await httpsCallables.buildTodaysTags(shopId);
    } catch (e) {
      throw e;
    }
    return "success";
  },
  subscribeAll: (
    id: string,
    statuses: number[],
    callback: (doc: Card[] | undefined) => void
  ) => {
    return firestore.subscribeCollectionWQuery<Card>(
      `shops/${id}/cards`,
      callback,
      "repairOrderStatus",
      "in",
      [...statuses, "manuallyCreated"]
    );
  },
  create: async (shopId: string, card: CreateCardDto) => {
    const fullCard = addCreationData({
      id: shortUUID.generate(),
      timers: [],
      createdVia: "dashboard",
      repairOrderStatus: "manuallyCreated",
      currentContainer: "dispatch",
      ...card,
    });
    try {
      const newCard = await firestore.createDocument(
        `shops/${shopId}/cards/${fullCard.id}`,
        fullCard
      );
      // Update shop dispatch container with new ID
      await shop.addCardToContainer(shopId, "dispatch", newCard.id);
      return newCard;
    } catch (e) {
      throw e;
    }
  },
  update: async (shopId: string, cardId: string, card: PatchCardDto) => {
    try {
      const updatedCard = await firestore.updateDocument(
        `shops/${shopId}/cards/${cardId}`,
        card
      );
      return updatedCard;
    } catch (e) {
      throw e;
    }
  },
  markImportant: async (shopId: string, cardId: string, isStarred: boolean) => {
    return card.update(shopId, cardId, { isStarred: isStarred });
  },
};
