import { firestore } from "@/services/firestore";
import { Role, User } from "@/types/User";
import { shop } from "./shop";

export const user = {
  getById: async (id: string) => {
    return firestore.getDocument<User>(`users/${id}`);
  },
  create: async (user: Partial<User>) => {
    return firestore.createDocument(`users/${user.id}`, user);
  },
  update: async (user: Partial<User>) => {
    return firestore.updateDocument(`users/${user.id}`, user);
  },
  subscribe: (id: string, callback: (doc: User | undefined) => void) => {
    return firestore.subscribeDocument<User>(`users/${id}`, callback);
  },
  getLinkedShops: async (id: string, role: Role) => {
    // Attach shops that user has access to
    let allowedShops = [];
    if (role === "superAdmin") {
      allowedShops = await shop.listShops();
    } else {
      allowedShops = await shop.listShopsByUser(id);
    }
    return allowedShops.filter((shop) => !shop.disabled);
  },
};
