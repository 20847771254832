import { Button } from "@/components/Base/Button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Base/Form";
import { Input } from "@/components/Base/Input";
import { CARD_FIELDS } from "@/lib/const";
import { useStore } from "@/stores/useStore";
import { CardFieldKey, PatchCardDto } from "@/types/Card";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { ZodObject, ZodRawShape, z } from "zod";

const shape: ZodRawShape = {};
CARD_FIELDS.forEach((field) => {
  shape[field.key] = z.string().optional();
});
const formSchema: ZodObject<typeof shape> = z.object({
  repairOrderNumber: z.string().min(1, {
    message: "Repair Order Number must exist",
  }),
  ...shape,
});

const defaultValues: { [key in CardFieldKey]?: string } = {};
CARD_FIELDS.forEach((field) => {
  defaultValues[field.key] = "";
});

export interface CardFormProps<T> {
  cancelButton?: ReactNode;
  submitButton?: ReactNode;
  cardData?: PatchCardDto;
  onSubmit: (values: T) => Promise<void>;
}
export function CardForm<T>({
  cancelButton,
  submitButton,
  cardData,
  onSubmit,
}: CardFormProps<T>) {
  const shop = useStore((s) => s.shop);

  const formDefaultValues = {
    repairOrderNumber: "",
    ...defaultValues,
    ...cardData,
  };

  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues,
  });
  // 2. Define a submit handler.

  if (!shop) return null;
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => onSubmit(data as T))}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="repairOrderNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Repair Order Number</FormLabel>
              <FormControl>
                <Input
                  disabled={form.formState.isSubmitting}
                  placeholder="0001"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    CARD_FIELDS.forEach((cardField) => {
                      if (shop.cardContents[cardField.key] == "RO Number") {
                        console.log(`Setting ${cardField.key}`);
                        form.setValue(cardField.key, e.target.value, {
                          shouldValidate: true,
                        });
                      }
                    });
                  }}
                  className="text-primary"
                />
              </FormControl>
              <FormDescription>
                This is the repair order number for the card.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        {CARD_FIELDS.map((cardField) => (
          <FormField
            key={cardField.key}
            control={form.control}
            name={cardField.key}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{cardField.label}</FormLabel>
                <FormDescription>
                  {`${shop.cardContents[cardField.key]}`}
                </FormDescription>
                <FormControl>
                  <Input
                    placeholder=""
                    {...field}
                    className="text-primary"
                    disabled={
                      shop.cardContents[cardField.key] == "RO Number" ||
                      form.formState.isSubmitting
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ))}
        <div className="flex flex-row justify-end gap-4">
          {cancelButton ? cancelButton : null}
          {submitButton ? (
            submitButton
          ) : (
            <Button
              variant="primary-alt"
              type="submit"
              disabled={form.formState.isSubmitting}
            >
              Submit
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
}
