import { user } from "@/models/user";
import { Shop } from "@/types/Shop";
import { Role, User } from "@/types/User";
import { Unsubscribe } from "firebase/firestore";
import { StateCreator } from "zustand";
import { User as AuthUser } from "firebase/auth";

export interface UserSlice {
  user: User | undefined;
  allowedShops: string[];
  allowedShopsExpanded: { id: string; name: string }[];
  subscribeUser: (authUser: AuthUser) => Promise<void>;
  userSubscription: Unsubscribe | undefined;
  unsubscribeUser: () => void;
  resetUser: () => void;
}

const initialSlice: UserSlice = {
  user: undefined,
  allowedShops: [],
  allowedShopsExpanded: [],
  userSubscription: undefined,
  subscribeUser: async () => {},
  unsubscribeUser: () => {},
  resetUser: () => {},
};

export const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = (
  set,
  get
) => ({
  ...initialSlice,
  resetUser: () => {
    set({ ...initialSlice });
  },

  subscribeUser: async (authUser: AuthUser) => {
    // Check if user in DB, if not, create
    const dbUser = await user.getById(authUser.uid);
    const claims = await authUser.getIdTokenResult();
    const role = (claims.claims?.role ?? "user") as Role;

    if (!dbUser) {
      await user.create({
        id: authUser.uid,
        uid: authUser.uid,
        displayName: authUser.displayName ?? "",
        email: authUser.email ?? "",
        emailVerified: authUser.emailVerified,
        creationTime: authUser.metadata.creationTime,
        lastLoginTime: new Date(),
        role: role ?? "user",
      });
    } else {
      await user.update({
        id: authUser.uid,
        uid: authUser.uid,
        lastLoginTime: new Date(),
        role: role ?? "user",
      });
    }

    const subscription = user.subscribe(authUser.uid, async (doc) => {
      if (!doc) {
        set({ user: undefined, allowedShops: [], allowedShopsExpanded: [] });
        return;
      }
      const allowedShops = (await user.getLinkedShops(doc.id, role)) as Shop[];

      console.log("allowedShops", allowedShops);
      set({
        user: doc,
        allowedShops: allowedShops.map((shop) => shop.id ?? shop.shopId),
        allowedShopsExpanded: allowedShops.map((shop) => ({
          id: shop.id ?? shop.shopId,
          name: shop.name,
        })),
      });
    });
    set({ userSubscription: subscription });
    return;
  },
  unsubscribeUser: () => {
    const unsubscribe = get().userSubscription;
    if (unsubscribe) unsubscribe();
    set({ userSubscription: undefined });
  },
});
