import { db } from "@/services/db";
import { doc, updateDoc } from "firebase/firestore";

export const updateLastSyncedTimestamp = async (payload) => {
  const docRef = doc(db, "shops", payload.shopId);

  // console.log("updateLastSyncedTimestamp: ",docRef)
  await updateDoc(docRef, {
    lastSyncedTimestamp: payload.lastSyncedTimestamp,
  }); //.then(val => console.log('updated firestore with value', payload.lastSyncedTimestamp, val));
};
