import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  FirestoreError,
  Query,
  WhereFilterOp,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./db";

/**
 * Handles a Firestore error by logging it to the console.
 *
 * @param {FirestoreError} error - The Firestore error to be handled.
 */
const onError = (error: FirestoreError) => {
  console.error(error);
};

/**
 * Firestore utility for interacting with Firestore documents.
 * @namespace
 */
export const firestore = {
  /**
   * Retrieve a Firestore document from the specified path.
   *
   * @param {string} path - The path to the Firestore document.
   * @returns {Promise<DocumentData>} A Promise that resolves to the document data.
   */
  getDocument: async <T = DocumentData>(
    path: string,
    converter?: FirestoreDataConverter<T>
  ) => {
    const docRef = (
      converter ? doc(db, path).withConverter(converter) : doc(db, path)
    ) as DocumentReference<T>;
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  },
  /**
   * Create a Firestore document at the specified path with the given data.
   *
   * @param {string} path - The path where the document should be created.
   * @param {T} data - The data to be stored in the document.
   * @returns {Promise<T>} A Promise that resolves to the created document data.
   */
  createDocument: async <T = DocumentData>(
    path: string,
    data: T,
    converter?: FirestoreDataConverter<T>
  ) => {
    const docRef = (
      converter ? doc(db, path).withConverter(converter) : doc(db, path)
    ) as DocumentReference<T>;
    const dataWId = { id: docRef.id, ...data };
    await setDoc(docRef, dataWId);
    return dataWId;
  },

  deleteDocument: async (path: string) => {
    const docRef = doc(db, path);
    await deleteDoc(docRef);
    return;
  },

  /**
   * Update a Firestore document at the specified path with the given data.
   *
   * @param {string} path - The path of the document to be updated.
   * @param {T} data - The updated data for the document.
   * @returns {Promise<T>} A Promise that resolves to the updated document data.
   */
  updateDocument: async <T = DocumentData>(
    path: string,
    data: T,
    converter?: FirestoreDataConverter<T>
  ) => {
    const docRef = (
      converter ? doc(db, path).withConverter(converter) : doc(db, path)
    ) as DocumentReference<T>;
    const dataWId = { id: docRef.id, ...data, _source: "client" };
    await updateDoc(docRef, dataWId);
    return dataWId;
  },
  queryDocuments: async <T = DocumentData>(
    path: string,
    searchParam: string,
    opsString: WhereFilterOp,
    equality: unknown,
    converter?: FirestoreDataConverter<T>
  ) => {
    const queryRef = query(
      collection(db, path),
      where(searchParam, opsString, equality)
    ) as Query<T>;
    const queryRefwConv = converter
      ? queryRef.withConverter(converter)
      : queryRef;
    const querySnapshot = await getDocs(queryRefwConv);
    const docs: Array<T> = [];
    querySnapshot.forEach((doc) => docs.push({ ...doc.data() }));
    return docs;
  },
  listDocuments: async <T = DocumentData>(
    path: string,
    converter?: FirestoreDataConverter<T>
  ) => {
    const queryRef = (
      converter
        ? collection(db, path).withConverter(converter)
        : collection(db, path)
    ) as Query<T>;
    const querySnapshot = await getDocs(queryRef);
    const docs: Array<T> = [];
    querySnapshot.forEach((doc) => docs.push({ ...doc.data() }));
    return docs;
  },

  /**
   * Subscribes to changes in a Firestore document and invokes a callback when the document changes.
   *
   * @param {string} path - The path to the Firestore document.
   * @param {(snapshot: DocumentSnapshot<DocumentData>) => void} callback - The callback function to be called when the document changes.
   * @returns {Unsubscribe} A function that can be called to unsubscribe from further document changes.
   */
  subscribeDocument: <T = DocumentData>(
    path: string,
    callback: (doc: T | undefined) => void
  ) => {
    const docRef = doc(db, path) as DocumentReference<T>;
    return onSnapshot(docRef, (doc) => callback(doc.data()), onError);
  },

  /**
   * Subscribes to changes in a Firestore collection and invokes a callback when the documents change.
   *
   * @param {string} path - The path to the Firestore collection.
   * @param {(array: Array<DocumentData>) => void} callback - The callback function to be called when the documents change.
   * @returns {Unsubscribe} A function that can be called to unsubscribe from further document changes.
   */
  subscribeCollection: <T = DocumentData>(
    path: string,
    callback: (docs: T[]) => void
  ) => {
    const colRef = collection(db, path) as CollectionReference<T>;
    return onSnapshot(
      colRef,
      (querySnapshot) => {
        const docs = querySnapshot.docs.map((doc) => doc.data());
        callback(docs);
      },
      onError
    );
  },

  /**
   * Subscribes to changes in a Firestore collection and invokes a callback when the documents change.
   *
   * @param {string} path - The path to the Firestore collection.
   * @param {(array: Array<DocumentData>) => void} callback - The callback function to be called when the documents change.
   * @returns {Unsubscribe} A function that can be called to unsubscribe from further document changes.
   */
  subscribeCollectionWQuery: <T = DocumentData>(
    collectionPath: string,
    callback: (docs: T[]) => void,

    searchParam?: string,
    opsString?: WhereFilterOp,
    equality?: unknown
  ) => {
    const collectionRef = collection(
      db,
      collectionPath
    ) as CollectionReference<T>;
    if (searchParam && opsString && equality) {
      const queryRef = query(
        collectionRef,
        where(searchParam, opsString, equality)
      ) as Query<T>;
      return onSnapshot(queryRef, (snapshot) => {
        const docs = snapshot.docs.map((doc) => doc.data());
        callback(docs);
      });
    }
    return onSnapshot(collectionRef, (snapshot) => {
      const docs = snapshot.docs.map((doc) => doc.data());
      callback(docs);
    });
  },
};
