import React, { useState } from "react";

export default function Tooltip({ message, children }) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isTooltipVisible && (
        <div
          className="absolute bg-ltGray text-black text-sm rounded-lg p-2 whitespace-nowrap z-10 opacity-75 -bottom-3/4 left-1/2 transform -translate-x-1/2"
          style={{}}
        >
          {message}
        </div>
      )}
    </div>
  );
}
