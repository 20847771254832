import { useStore } from "@/stores/useStore";
import shdbLogo from "../../assets/ShdbLogo.png";
import { Button } from "../Base/Button";
import { Menu as MenuIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Feature, useFeature } from "flagged";

import Tooltip from "@/components/Common/Tooltip";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

import { employees, layouts } from "@/data/Context/shop";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Base/Select";
import { CardFormDialog } from "../Dashboard/components/Card/CardFormDialog";
import { CreateCardDto } from "@/types/Card";
import { card } from "@/models/cards";

export interface HeaderProps {
  onMenuClick: () => void;
}

export const Header = ({ onMenuClick }: HeaderProps) => {
  const serviceAdvisors = employees.use();
  const layoutsData = layouts.use();
  const {
    logoUrl,
    authState,
    shopId,
    dashboardServiceWriterId,
    setDashboardServiceWriterId,
    dashboardLayoutId,
    setDashboardLayoutId,
    lastSyncedTime,
    syncError,
    syncButtonText,
    syncWithTekmetric,
    isSyncing,
  } = useStore();

  const isManualCardOnlyEnabled = useFeature("isManualCardOnlyEnabled");

  const handleServiceWriteChange = (value: string) => {
    setDashboardServiceWriterId(value);
  };

  const handleLayoutSelect = (value: string) => {
    setDashboardLayoutId(value);
  };

  const handleManualOnSubmit = async (values: CreateCardDto) => {
    if (!shopId) return;
    await card.create(shopId, values);
    return;
  };

  return (
    <div className="text-lg flex flex-row justify-between items-center px-2 py-2 shadow-lg">
      <Button
        variant="ghost"
        size="icon"
        onClick={onMenuClick}
        className="m-2"
        disabled={authState !== "authenticated"}
      >
        <MenuIcon />
      </Button>
      <div>
        <img
          src={logoUrl ? logoUrl : shdbLogo}
          alt="logo"
          style={{ height: "60px" }}
        />
      </div>
      <div className="flex-grow"></div>
      <div>
        <div className="flex flex-row items-end gap-4">
          {authState === "unauthenticated" && (
            <Link className="px-10" to="">
              Login
            </Link>
          )}
          {authState === "unauthenticated" && (
            <Link className="px-10" to="signup">
              Signup
            </Link>
          )}
          {authState === "authenticated" && shopId && (
            <>
              <Feature name="isManualCardsEnabled">
                <CardFormDialog onSubmit={handleManualOnSubmit} />
              </Feature>
              <Feature name="isManualCardOnlyEnabled">
                {(enabled) =>
                  enabled ? (
                    <CardFormDialog onSubmit={handleManualOnSubmit} />
                  ) : (
                    <div className="flex flex-col">
                      <div>
                        <div>
                          <p className="text-xs text-center">
                            Last Sync:{" "}
                            {new Date(lastSyncedTime).toLocaleTimeString()}
                          </p>
                          {syncError && (
                            <Tooltip message="Last sync failed!">
                              <ExclamationCircleIcon
                                // className="h-10 w-10 text-amber align-center"
                                style={{
                                  height: "2.5rem", // h-10 is 2.5rem assuming the base is 4 per unit
                                  width: "2.5rem", // w-10 is 2.5rem assuming the base is 4 per unit
                                  color: "#ffbf00", // Example color for text-amber
                                  textAlign: "center", // If you meant to center the text
                                }}
                                aria-hidden="true"
                              />
                            </Tooltip>
                          )}
                        </div>
                        <Button
                          variant={"secondary"}
                          disabled={isSyncing}
                          onClick={() => syncWithTekmetric(true)}
                        >
                          {syncButtonText}
                        </Button>
                      </div>
                    </div>
                  )
                }
              </Feature>

              <Feature name="isServiceAdvisorFilterEnabled">
                <div className="flex flex-col">
                  <p className="text-xs text-center text-primary">
                    Service Advisor
                  </p>
                  <div>
                    <Select
                      onValueChange={(value) => handleServiceWriteChange(value)}
                      value={dashboardServiceWriterId ?? "all"}
                    >
                      <SelectTrigger className="w-48">
                        <SelectValue placeholder="Service Advisor" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">Show All</SelectItem>
                        {serviceAdvisors
                          .filter((sa) => !sa.hideInShopHero)
                          .map((serviceAdvisor) => (
                            <SelectItem
                              key={serviceAdvisor.id}
                              value={`${serviceAdvisor.id}`}
                            >
                              {serviceAdvisor.firstName}{" "}
                              {serviceAdvisor.lastName}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </Feature>

              <div className="flex flex-col">
                <p className="text-xs text-center text-primary">Layout</p>

                <Select
                  onValueChange={(value) => handleLayoutSelect(value)}
                  value={dashboardLayoutId}
                >
                  <SelectTrigger className="w-48">
                    <SelectValue
                      placeholder="Service Layout"
                      defaultValue={"0"}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {layoutsData.map((obj, index) => {
                      const name = Object.keys(obj)[0];
                      return (
                        <SelectItem key={name} value={`${index}`}>
                          {name}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
