import { useEffect } from 'react';
import { config } from '@/config';

export const Footer = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js-na1.hs-scripts.com/46067825.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <footer className="text-xs flex flex-row justify-between px-2">
      <div>ShopHero Dashboard</div>
      <div className="flex-grow"></div>
      <div>
        <p>
          <span>{`v${config.version}-${config.commitHash}`}</span>
        </p>
      </div>
    </footer>
  );
};
