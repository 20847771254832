import React from "react";
import { BasePage } from "@/components/Page/BasePage";
import { useStore } from "@/stores/useStore";
import { AuthState } from "@/types/Auth";
import {
  RouterProvider as BaseRouterProvider,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Admin } from "@/components/Admin/Admin";
import { Users } from "@/components/Users/Users";
import { SuperAdmin } from "@/components/SuperAdmin/SuperAdmin";
import { Spinner } from "@/components/Common/Spinner";
import { GlobalConfigPage } from "@/pages/(superAdmin)/GlobalConfig.page";
import { ShopConfigPage } from "@/pages/(superAdmin)/ShopConfig.page";
import EmployeesPage from "@/pages/(shop)/Employees";
import DebugCardCreatePage from "@/pages/(superAdmin)/(debug)/CreateCard.page";
import ErrorPage from "@/pages/Error.Page";

const SignupPage = React.lazy(() => import("@/pages/(auth)/Signup.page"));
const ResetPasswordPage = React.lazy(
  () => import("@/pages/(auth)/ResetPassword.page")
);
const LoginPage = React.lazy(() => import("@/pages/(auth)/Login.page"));
const VerifyPage = React.lazy(() => import("@/pages/(auth)/Verify.page"));
const DebugPage = React.lazy(() => import("@/pages/(superAdmin)/Debug.page"));
const IsAuthenticatedRedirect = React.lazy(
  () => import("@/pages/IsAuthenticated.redirect")
);
const DashboardPage = React.lazy(() => import("@/pages/Dashboard.page"));
const CardConfig = React.lazy(() => import("@/pages/(config)/CardConfig.page"));
const LayoutBuilder = React.lazy(
  () => import("@/pages/(config)/LayoutBuilder.page")
);
const Integrations = React.lazy(
  () => import("@/pages/(config)/Integrations.page")
);
const CompanyInfo = React.lazy(
  () => import("@/pages/(config)/CompanyInfo.page")
);

const router = ({
  authState,
  logoExists,
  logoUrl,
  shopId,
  dashboardKey,
}: {
  authState: AuthState;
  logoExists?: boolean;
  logoUrl?: string;
  shopId?: string;
  dashboardKey?: string;
}) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        errorElement={
          <BasePage>
            <React.Suspense fallback={<Spinner />}>
              <ErrorPage />
            </React.Suspense>
          </BasePage>
        }
        element={
          <BasePage>
            <React.Suspense fallback={<Spinner />}>
              <Outlet />
            </React.Suspense>
          </BasePage>
        }
      >
        <Route path="/verify" element={<VerifyPage />} />
        {authState === "unauthenticated" && (
          <Route path="" element={<LoginPage />} />
        )}
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />} />

        {authState === "unverified" && (
          <Route path="" element={<VerifyPage />} />
        )}

        {authState === "authenticated" && (
          <>
            <Route path="/debug" element={<Outlet />}>
              <Route path="" element={<DebugPage />} />
              <Route path="cards" element={<Outlet />}>
                <Route path="create" element={<DebugCardCreatePage />} />
              </Route>
            </Route>
            <Route path="features" element={<Outlet />}>
              <Route path="global" element={<GlobalConfigPage />} />
              <Route path="shop" element={<ShopConfigPage />} />
            </Route>
            <Route path="/" element={<DashboardPage />} />
            <Route path="companyInfo" element={<CompanyInfo />} />
            <Route path="integrations" element={<Integrations />} />
            <Route path="cardConfig" element={<CardConfig />} />
            <Route path="layoutBuilder" element={<LayoutBuilder />} />
            <Route path="employees" element={<EmployeesPage />} />

            <Route
              path="users"
              element={
                <Users
                  logoExists={logoExists}
                  logoUrl={logoUrl}
                  shopId={shopId}
                  dashboardKey={dashboardKey}
                />
              }
            />

            {/* <Route
          path="billing"
          element={
            <Billing
              logoExists={logoExists}
              logoUrl={logoUrl}
              shopId={shopId}
              dashboardKey={dashboardKey}
            />
          }
        /> */}

            <Route
              path="admin"
              element={
                <Admin
                  logoExists={logoExists}
                  logoUrl={logoUrl}
                  shopId={shopId}
                />
              }
            />

            <Route path="superAdmin" element={<SuperAdmin />} />
          </>
        )}
        <Route path="*" element={<IsAuthenticatedRedirect />} />
      </Route>
    )
  );
export const RouterProvider = () => {
  const authState = useStore((s) => s.authState);
  const { logoExists, logoUrl, shopId } = useStore();
  console.log("RouterProvider", authState);
  return (
    <BaseRouterProvider
      router={router({
        authState,
        logoExists,
        logoUrl,
        shopId,
        dashboardKey: "101",
      })}
    />
  );
};
