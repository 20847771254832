import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";
import { PropsWithChildren } from "react";
import GarageIcon from "../../assets/garage.svg";
import CardIcon from "../../assets/card.svg";
import IntegrationIcon from "../../assets/integration.svg";
import LayoutIcon from "../../assets/layout.svg";
import DashboardIcon from "../../assets/dashboard.svg";
import SupportIcon from "../../assets/support.png";
import PhoneIcon from "../../assets/phone.png";
import LogoutIcon from "../../assets/logout.png";
import { setCurrentShopId } from "../../data/Context/shop";
import { useStore } from "@/stores/useStore";
import { AppVersion } from "../Common/AppVersion";
import { useIsSuperAdmin } from "@/hooks/useIsSuperAdmin";
import { FlagIcon, GlobeLockIcon, UsersIcon } from "lucide-react";
import { Separator } from "../Base/Separator";

export interface SidebarProps {
  open: boolean;
  onClose: () => void;
}

const SideBarItem = ({ children }: PropsWithChildren) => {
  return <div className="mb-2.5 flex flex-row items-center">{children}</div>;
};

export const Sidebar = ({ open, onClose }: SidebarProps) => {
  const [, isSuperAdmin] = useIsSuperAdmin();
  const activeShopId = useStore((s) => s.shopId);
  const logout = useStore((s) => s.logout);
  const allowedShopsExpanded = useStore((s) => s.allowedShopsExpanded);

  const sortedShops = allowedShopsExpanded.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });

  const handleLogout = () => logout();
  return (
    <aside
      className={cn(
        "absolute w-[100vw] h-full flex flex-row text-accent-foreground text-sm transition-all duration-500 z-10 shadow-xl",
        open ? "left-0" : "-left-[100vw]"
      )}
    >
      <div className="flex flex-col w-64 bg-accent h-full p-2">
        <SideBarItem>
          {sortedShops?.length > 1 && (
            <div
              style={{
                marginTop: 20,
                width: "100%",
                marginRight: "1em",
              }}
            >
              <p>Active Shop:</p>
              <select
                value={activeShopId ?? ""}
                style={{
                  color: "#000000",
                  padding: 10,
                  border: "1px solid gray",
                  borderRadius: 10,
                  width: "100%",
                  backgroundColor: "#ffffff",
                }}
                onChange={(e) => setCurrentShopId(e.target.value)}
                name="cardPrimaryField"
              >
                {sortedShops.map((shop, index: number) => {
                  return (
                    <option key={index} value={shop.id}>
                      {shop.name !== "" && shop.name !== "undefined"
                        ? shop.name
                        : shop.id}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </SideBarItem>

        <SideBarItem>
          <img
            style={{
              height: 24,
              width: 24,
              marginRight: 10,
              marginTop: 3,
            }}
            src={DashboardIcon}
            alt="Layout"
          />
          <Link style={{ color: "#fff" }} to="../" onClick={onClose}>
            Dashboard
          </Link>
        </SideBarItem>

        <Separator className="my-2" />

        <SideBarItem>
          <img
            style={{
              height: 24,
              width: 24,
              marginRight: 10,
              marginTop: 3,
            }}
            src={GarageIcon}
            alt="Settings"
          />
          <Link style={{ color: "#fff" }} to="../companyInfo" onClick={onClose}>
            Company Info
          </Link>
        </SideBarItem>

        <SideBarItem>
          <img
            style={{
              height: 24,
              width: 24,
              marginRight: 10,
              marginTop: 3,
            }}
            src={LayoutIcon}
            alt="Settings"
          />
          <Link
            style={{ color: "#fff" }}
            to="../layoutBuilder"
            onClick={onClose}
          >
            Layout Builder
          </Link>
        </SideBarItem>

        <SideBarItem>
          <img
            style={{
              height: 24,
              width: 24,
              marginRight: 10,
              marginTop: 3,
            }}
            src={CardIcon}
            alt="Settings"
          />
          <Link style={{ color: "#fff" }} to="../cardConfig" onClick={onClose}>
            Card Configuration
          </Link>
        </SideBarItem>

        <SideBarItem>
          <img
            style={{
              height: 24,
              width: 24,
              marginRight: 10,
              marginTop: 3,
            }}
            src={IntegrationIcon}
            alt="Settings"
          />
          <Link
            style={{ color: "#fff" }}
            to="../integrations"
            onClick={onClose}
          >
            Integrations
          </Link>
        </SideBarItem>

        <SideBarItem>
          <UsersIcon className="mt-[3px] mr-2.5" />
          <Link style={{ color: "#fff" }} to="/employees" onClick={onClose}>
            Employees
          </Link>
        </SideBarItem>
        {isSuperAdmin && (
          <>
            <Separator className="my-2" />
            <SideBarItem>
              <GlobeLockIcon className="mt-[3px] mr-2.5" />
              <Link
                style={{ color: "#fff" }}
                to="/features/global"
                onClick={onClose}
              >
                Global Feature Flags
              </Link>
            </SideBarItem>
            <SideBarItem>
              <FlagIcon className="mt-[3px] mr-2.5" />
              <Link
                style={{ color: "#fff" }}
                to="/features/shop"
                onClick={onClose}
              >
                Shop Feature Flags
              </Link>
            </SideBarItem>
          </>
        )}

        <div className="flex-grow" />
        <SideBarItem>
          <img
            style={{
              height: 24,
              width: 24,
              marginRight: 10,
              marginTop: 3,
            }}
            src={SupportIcon}
            alt="Support"
          />
          <a
            style={{ color: "#fff" }}
            href="mailto:myhero@shopherodashboard.com"
          >
            Support
          </a>
        </SideBarItem>
        <SideBarItem>
          <img
            style={{
              height: 24,
              width: 24,
              marginRight: 10,
              marginTop: 3,
            }}
            src={PhoneIcon}
            alt="814-208-5088"
          />
          <a style={{ color: "#fff" }} href="tel:+18142085088">
            814-208-5088
          </a>
        </SideBarItem>
        <SideBarItem>
          <img
            style={{
              height: 24,
              width: 24,
              marginRight: 10,
              marginTop: 3,
            }}
            src={LogoutIcon}
            alt="Logout"
          />
          <div>
            <button
              style={{
                color: "#ffffff",
                backgroundColor: "transparent",
                padding: 10,
                paddingTop: 0,
                paddingLeft: 0,
                borderRadius: 10,
              }}
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </SideBarItem>
        <AppVersion />
      </div>
      <div
        className={cn(
          "h-full flex-grow bg-primary opacity-75",
          open ? "visible" : "hidden"
        )}
        onClick={onClose}
      ></div>
    </aside>
  );
};
