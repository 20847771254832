import { entity } from "simpler-state";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

export const token = entity(null);

export const setToken = async () => {
  const docRef = doc(db, "integrations", "tekmetric");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    token.set(data.API_KEY);
  } else {
    console.error("Error fetching token from Firestore");
  }
};
