import { useEffect } from "react";
import { FlagsProvider, ReactQueryProvider, RouterProvider } from "./providers";
import { useStore } from "./stores/useStore";
import { Spinner } from "./components/Common/Spinner";
import { setCurrentShopId } from "./data/Context/shop";
import { featureFlags } from "./data/Context/featureFlags";
import { setToken } from "./data/Context/token";
import { KEYS, localStorageManager } from "./services/localStorage";
import { TooltipProvider } from "./providers/Tooltip.provider";

export const App = () => {
  const store = useStore();
  console.log(store);

  useEffect(() => {
    store.authListener();
  }, []);

  useEffect(() => {
    if (store.authUser?.emailVerified === false) {
      setCurrentShopId("unverified");
      return;
    }
    if (store.authUser && store.user?.uid !== store.authUser.uid) {
      setToken();
    } else if (!store.authUser) {
      featureFlags.init();
    } else {
      featureFlags.init();
    }
  }, [store.authUser]);

  useEffect(() => {
    const lastShopId = localStorageManager.getItem(KEYS.SHOP_ID);

    if (store.allowedShops && store.allowedShops.length === 1) {
      setCurrentShopId(store.allowedShops[0]);
    } else if (
      lastShopId &&
      store.allowedShops &&
      store.allowedShops.includes(lastShopId)
    ) {
      setCurrentShopId(lastShopId);
    }
  }, [store.allowedShops]);

  if (!store.initialized) {
    return (
      <div className="flex flex-col h-screen overscroll-none">
        <div className="flex flex-grow overflow-hidden bg-white border-2 relative">
          <main className="flex-1 flex-grow flex flex-col overflow-x-auto overflow-y-auto bg-primary text-primary-foreground">
            <div className="m-auto">
              <Spinner />
            </div>
          </main>
        </div>
      </div>
    );
  }

  return (
    <ReactQueryProvider>
      <FlagsProvider>
        <TooltipProvider>
          <RouterProvider />
        </TooltipProvider>
      </FlagsProvider>
    </ReactQueryProvider>
  );
};
