import { firestore } from "@/services/firestore";
import { Employee } from "@/types/Employee";

export const employees = {
  subscribeAll: (
    id: string,
    callback: (doc: Employee[] | undefined) => void
  ) => {
    return firestore.subscribeCollection<Employee>(
      `shops/${id}/employees`,
      callback
    );
  },
  update: async (
    shopId: string,
    employeeId: string,
    employeeData: Partial<Employee>
  ) => {
    return firestore.updateDocument<Partial<Employee>>(
      `shops/${shopId}/employees/${employeeId}`,
      { ...employeeData }
    );
  },
};
