import { db } from "@/services/db";
import { firestore } from "@/services/firestore";
import { FeatureFlag, FeatureFlags } from "@/types/FeatureFlag";
import { DocumentReference, doc, updateDoc } from "firebase/firestore";

export const FEATURE_FLAG = {
  isCardColorEnabled: "isCardColorEnabled",
  isCardLaborEnabled: "isCardLaborEnabled",
  isCardPrimaryLinkEnabled: "isCardPrimaryLinkEnabled",
  isPromiseTimeEnabled: "isPromiseTimeEnabled",
  isServiceAdvisorFilterEnabled: "isServiceAdvisorFilterEnabled",
  isSyncV2Enabled: "isSyncV2Enabled",
  isTechnicianLinkEnabled: "isTechnicianLinkEnabled",
  isROLabelLinkEnabled: "isROLabelLinkEnabled",
  isCardColorSyncEnabled: "isCardColorSyncEnabled",
  isManualCardOnlyEnabled: "isManualCardOnlyEnabled",
  isManualCardsEnabled: "isManualCardsEnabled",
  isCardNotesEnabled: "isCardNotesEnabled",
};

export type FEATURE_FLAG = (typeof FEATURE_FLAG)[keyof typeof FEATURE_FLAG];

type FEATURE_FLAG_OPTIONS = {
  [key in FEATURE_FLAG]: {
    label: string;
    canEnableGlobally: boolean;
    canEnablePerUser: boolean;
    canEnableTestUsers: boolean;
    obsolete?: boolean;
    canDisable?: boolean;
    mutuallyExclusiveWith?: FEATURE_FLAG;
    exclusiveWith?: FEATURE_FLAG;
  };
};
export const FEATURE_FLAG_OPTIONS: FEATURE_FLAG_OPTIONS = {
  [FEATURE_FLAG.isCardColorEnabled]: {
    label: "Card Color",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
  },
  [FEATURE_FLAG.isCardLaborEnabled]: {
    label: "Card Labor",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
  },
  [FEATURE_FLAG.isCardPrimaryLinkEnabled]: {
    label: "Card Primary Link",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
  },
  [FEATURE_FLAG.isPromiseTimeEnabled]: {
    label: "Promise Time",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
  },
  [FEATURE_FLAG.isServiceAdvisorFilterEnabled]: {
    label: "Service Advisor Filter",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
  },
  [FEATURE_FLAG.isSyncV2Enabled]: {
    label: "Sync V2",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
    canDisable: false,
    obsolete: true,
  },
  [FEATURE_FLAG.isTechnicianLinkEnabled]: {
    label: "Technician Container Linking",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
  },
  [FEATURE_FLAG.isROLabelLinkEnabled]: {
    label: "Repair Order Label Linking",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
  },
  [FEATURE_FLAG.isCardColorSyncEnabled]: {
    label: "Syncing Card Color",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
  },
  [FEATURE_FLAG.isManualCardOnlyEnabled]: {
    label: "Manual Card Only",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
    // mutuallyExclusiveWith: FEATURE_FLAG.isManualCardsEnabled,
    exclusiveWith: FEATURE_FLAG.isManualCardsEnabled,
  },
  [FEATURE_FLAG.isManualCardsEnabled]: {
    label: "Manual Cards (with Tekmetric)",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
    // mutuallyExclusiveWith: FEATURE_FLAG.isManualCardOnlyEnabled,
    exclusiveWith: FEATURE_FLAG.isManualCardOnlyEnabled,
  },
  [FEATURE_FLAG.isCardNotesEnabled]: {
    label: "Card Notes",
    canEnableGlobally: true,
    canEnablePerUser: true,
    canEnableTestUsers: true,
  },
};

const featureFlags = {
  create: (ff: string) => {
    const docRef = doc(
      db,
      "config/featureFlags"
    ) as DocumentReference<FeatureFlags>;
    return updateDoc(docRef, {
      [ff]: {
        allUsers: false,
        testUsers: [],
      },
    });
  },
  get: (ff: string) => {
    return firestore
      .getDocument<FeatureFlags>("config/featureFlags")
      .then((res) => (res ? res[ff] : undefined));
  },
  update: (ff: string, data: FeatureFlag) => {
    const docRef = doc(
      db,
      "config/featureFlags"
    ) as DocumentReference<FeatureFlags>;
    return updateDoc(docRef, {
      [ff]: data,
    });
  },
  list: () => {
    return firestore.getDocument<FeatureFlags>("config/featureFlags");
  },
};

export { featureFlags };
