import { config } from "@/config";
import { functions } from "@/services/functions";
import { Timer } from "@/types/Timer";
import { httpsCallable } from "firebase/functions";

export const httpsCallables = {
  buildTodaysTags: (shopId: string) => {
    const callable = httpsCallable(functions, "bulidTodaysTags");
    return callable({ shopId, endpoint: config.apiEndpoints.tekmetric });
  },
  updateShopTimers: (shopId: string, timerId: string, timerData?: Timer) => {
    const callable = httpsCallable(functions, "updateShopTimer");
    if (timerData) {
      return callable({ shopId, timerId, timerData }).then(() =>
        console.log("Finished")
      );
    }
    return callable({ shopId, timerId }).then(() => console.log("Finished"));
  },
};
