import { EnableDisableSwitch } from "@/components/Common/EnableDisableSwitch";
import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { employees } from "@/models/employees";
import { useStore } from "@/stores/useStore";

const EmployeesPage = () => {
  useIsAuthenticated();
  const employeesData = useStore((s) => s.employees);
  const shopId = useStore((s) => s.shopId);

  const handleEmployeeSwitch = (id: number, enabled: boolean) => {
    if (!shopId) return;
    employees.update(shopId, `${id}`, { hideInShopHero: enabled });
  };

  return (
    <>
      <div className="pt-5 px-2">
        <h1 style={{ fontSize: 24 }}>Employees</h1>
        {!employeesData && <div>Loading...</div>}
        {employeesData && (
          <div>
            {employeesData.map((employee, index) => (
              <div
                key={employee.id}
                className="py-2 px-4 border-b border-gray-300 flex flex-col"
              >
                <div className="text-xl">{`${employee.firstName} ${employee.lastName}`}</div>

                <EnableDisableSwitch
                  key={`${employee.id}-${index}`}
                  label={"Show In Shop Hero"}
                  enabled={!employee.hideInShopHero}
                  onChange={(enabled) =>
                    handleEmployeeSwitch(employee.id ?? 0, !enabled)
                  }
                  enabledText="Show"
                  disabledText="Hide"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default EmployeesPage;
