import { firestore } from "@/services/firestore";
import { Container, Shop } from "@/types/Shop";
import { generic } from "./generic";
export const shop = {
  listShopsByUser: async (userId: string) => {
    const shops = await firestore.queryDocuments<Shop>(
      "shops",
      "users",
      "array-contains",
      userId
    );
    const asShop = await firestore.getDocument<Shop>(`shops/${userId}`);
    if (asShop) shops.push(asShop);
    return shops;
  },
  listShops: async () => {
    return firestore.listDocuments<Shop>("shops");
  },
  subscribeShop: async (
    id: string,
    callback: (doc: Shop | undefined) => void
  ) => {
    return firestore.subscribeDocument<Shop>(`shops/${id}`, callback);
  },
  update: async (shopId: string, shopData: Partial<Shop>) => {
    return generic.update({ id: shopId, ...shopData }, `shops/${shopId}`);
  },
  setStatuses: async (shopId: string, statuses: number[]) => {
    return shop.update(shopId, { statuses: statuses });
  },
  updateCardContent: async (
    shopId: string,
    fieldName: string,
    fieldValue: string,
    cardContents: Shop["cardContents"]
  ) => {
    return shop.update(shopId, {
      cardContents: { ...cardContents, [fieldName]: fieldValue },
    });
  },
  updateDefaultTimerValue: async (shopId: string, defaultTimer: string) => {
    return shop.update(shopId, { defaultTimer: defaultTimer });
  },
  updateCardLabelWrap: async (shopId: string, cardLabelWrap: string) => {
    return shop.update(shopId, { cardLabelWrap: cardLabelWrap });
  },
  get: (shopId: string) => {
    return firestore.getDocument<Shop>(`shops/${shopId}`);
  },
  addCardToContainer: async (
    shopId: string,
    containerId: string,
    cardId: string
  ) => {
    const shopData = await shop.get(shopId);

    if (!shopData) {
      throw new Error(`Shop ${shopId} not found`);
    }

    let containers = shopData.containers;

    const containerIndex = containers.findIndex(
      (container) => container.id === containerId
    );

    if (containerIndex === -1) {
      throw new Error(`Container ${containerId} not found for shop ${shopId}`);
    }

    // Remove card from old container
    const oldContainers = locateCardContainers(containers, cardId);
    oldContainers.forEach((oldContainerId) => {
      const oldContainer = containers.find(
        (container) => container.id === oldContainerId
      );
      if (oldContainer) {
        oldContainer.tags = oldContainer.tags.filter((tag) => tag !== cardId);
        containers[containers.indexOf(oldContainer)] = oldContainer;
      }
    });

    const container = containers[containerIndex];
    container.tags.push(cardId);
    containers[containerIndex] = container;

    return shop.update(shopId, {
      containers: containers,
    });
  },
  removeCardFromContainers: async (shopId: string, cardId: string) => {
    const shopData = await shop.get(shopId);

    if (!shopData) {
      throw new Error(`Shop ${shopId} not found`);
    }

    let containers = shopData.containers;

    // Remove card from old container
    const oldContainers = locateCardContainers(containers, cardId);
    oldContainers.forEach((oldContainerId) => {
      const oldContainer = containers.find(
        (container) => container.id === oldContainerId
      );
      if (oldContainer) {
        oldContainer.tags = oldContainer.tags.filter((tag) => tag !== cardId);
        containers[containers.indexOf(oldContainer)] = oldContainer;
      }
    });

    return shop.update(shopId, {
      containers: containers,
    });
  },
};

const locateCardContainers = (containers: Container[], cardId: string) => {
  const cardContainers: string[] = [];

  for (const container of containers) {
    if (container.tags.includes(cardId)) {
      cardContainers.push(container.id);
    }
  }

  return cardContainers;
};
