import { connectAuthEmulator, getAuth } from "firebase/auth";
import { app } from "./app";
import { config } from "@/config";
import { AuthRole } from "@/types/Auth";

const auth = getAuth(app);

if (config.emulator) {
  connectAuthEmulator(auth, "http://localhost:9099");
}

const authActions = {
  getUserClaims: async () => {
    const user = auth.currentUser;
    if (user) {
      const claims = await user.getIdTokenResult();
      return claims.claims;
    }
    return {};
  },
  getUserClaimRole: async () => {
    const claims = await authActions.getUserClaims();
    if (claims.role) {
      return claims.role as AuthRole;
    }
    if (claims) {
      return "user" as AuthRole;
    }
    return;
  },
};

export { auth, authActions };
